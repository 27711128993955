<template>
    <div class="text-page">
        <Content v-if="page" :paddingTop="true" :paddingBottom="true">
            <PageHeader :title="page.title"/>
            <div v-html="$md(page.content)"></div>
        </Content>
        <slot name="footer"/>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import Content from '@/components/Content'

    export default {
        name: 'TextPage',

        components: {
            PageHeader,
            Content
        },

        props: ['query'],

        apollo: {
            page() {
                return {
                    query: this.query,
                    update: data => Object.values(data)[0].data.attributes
                }
            }
        },

        computed: {
            pageTitle() {
                if(this.page){
                    return this.page.title
                }
                else if(this.$apollo.loading) {
                    return '...'
                }
                else {
                    return '404'
                }
            }
        },

        metaInfo() {
            var title = this.pageTitle
            return {
                title: `● ${title}`
            }
        }
    }
</script>
